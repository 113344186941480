.logo {
    width: 100%;
    max-width: 350px;
    &-text {
    }
    &-symbol-upper {
    }
    &-symbol-lower {
    }
}
