html,
body {
    margin: 0;
    padding: 0;
}
body {
    background: linear-gradient(to top right, white, $secondary);
}
* {
    box-sizing: border-box;
}
