// Colors
// $light: #e9f2f6;
// $primary: #315877;
// $secondary: #9bbdd3;
// $dark: #292929;

// changed to css vars, printed in umbraco template
$light: var(--light);
$primary: var(--primary);
$secondary: var(--secondary);
$dark: var(--dark);


// Partials
@import "partials/global";
@import "partials/typography";

// Components
@import "components/BusinessCard";
@import "components/Logo";

:export {
    primaryColor: $primary;
    secondaryColor: $secondary;
    lightColor: $light;
    darkColor: $dark;
}
