@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
// font-family: 'Merriweather', serif;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&display=swap");
// font-family: 'IBM Plex Sans', sans-serif;

html {
    font-size: 20px;
    font-family: "IBM Plex Sans", sans-serif;
    color: $dark;
}

h1 {
    font-family: "Merriweather", serif;
    color: $primary;
    font-size: 2rem;
    line-height: 2rem;
    margin-block-start: 0;
    margin-block-end: 0;

    @media screen and (max-width: 700px) {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
}
