.business-card-wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-image: url("../../card.png");
    background-position: center -20vh;
    background-size: 30vh auto;
    background-repeat: no-repeat;
    padding-top: 30vh;
    @media screen and (max-width: 700px) {
        align-items: flex-start;
        padding-top: 1rem;
        padding-bottom: 10vh;
        background-position: center -23vh;
        background-size: 15vh auto;
    }
}
.business-card {
    background: white;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 10px 10px 50px rgba($primary, 0.3);
    max-width: 400px;
    @media screen and (max-width: 700px) {
        max-width: 90vw;
    }
    &_logo {
        padding: 1rem;
        height: auto;
        margin: auto;
        display: block;
    }
    &_content {
        background: $light;
        height: 100%;
        padding: 2rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        &_qr canvas {
            width: 200px;
            height: 200px;
            @media screen and (max-width: 700px) {
                width: 160px;
                height: 160px;
            }
        }
        &_image {
            object-fit: cover;
            width: 200px;
            height: 200px;
            @media screen and (max-width: 700px) {
                width: 160px;
                height: 160px;
            }
            border-radius: 25%;
            box-shadow: 10px 10px 50px rgba($primary, 0.3);
            margin: 2rem auto;
            display: block;
        }
    }
    & ul {
        list-style: none;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        padding-top: 1rem;
        & li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
        }
    }
    & hr {
        border: 1px solid $secondary;
        width: 100%;
    }
}
